<template>
  <div class="admin-page">
    <div class="section">
      <h3>全局邮箱验证码</h3>
      <div class="operation-wrap" style="margin-bottom: 25px;">
        <span style="font-size:30px; line-height: 1;" class="email-code">{{
          emailCode
        }}</span>
      </div>
      <h3>解封用户</h3>
      <div class="operation-wrap">
        <el-input placeholder="请输入解封用户ID" v-model="blockUid">
          <template slot="prepend">用户ID</template>
        </el-input>
        <van-button type="primary" :disabled="!blockUid" @click="unblock"
          >确定</van-button
        >
      </div>
      <h3 class="title" style="margin-top: 20px;">申请临时解封列表</h3>
      <el-table :data="applyUnblockList" border>
        <el-table-column prop="updatetime" label="申请时间" />
        <el-table-column prop="email" label="邮箱" />
        <el-table-column prop="times" label="历史解封次数" />
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-link
              type="danger"
              style="margin-left: 4px;"
              @click="() => handleRow(scope.row, -1)"
              >拒绝</el-link
            >
            <el-link
              type="success"
              style="margin-left: 4px;"
              @click="() => handleRow(scope.row, 1)"
              >通过</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="section">
      <h3>处理退款用户</h3>
      <div class="operation-wrap">
        <el-input placeholder="请输入退款用户ID" v-model="refundUid">
          <template slot="prepend">用户ID</template>
        </el-input>
        <van-button type="primary" :disabled="!refundUid" @click="refund"
          >确定</van-button
        >
      </div>
    </div>
    <div class="section">
      <h3 class="title">网站渠道总览数据</h3>
      <div class="overview-wrap">
        <div class="overview-item">
          <p class="cur">注册人数</p>
          <p class="num">{{ overview.registerNum || "-" }}</p>
          <p class="tip">当月：{{ overview.curMonthRegisterNum || "-" }}</p>
          <p class="tip">上月：{{ overview.prevMonthRegisterNum || "-" }}</p>
        </div>
        <div class="overview-item">
          <p class="cur">订单总数</p>
          <p class="num">{{ overview.orderNum || "-" }}</p>
          <p class="tip">当月：{{ overview.curMonthOrderNum || "-" }}</p>
          <p class="tip">上月：{{ overview.prevMonthOrderNum || "-" }}</p>
        </div>
        <div class="overview-item">
          <p class="cur">订单总额</p>
          <p class="num">￥{{ overview.orderPrice || "-" }}</p>
          <p class="tip">当月：￥{{ overview.curMonthOrderPrice || "-" }}</p>
          <p class="tip">上月：￥{{ overview.prevMonthOrderPrice || "-" }}</p>
        </div>
      </div>
    </div>
    <div class="section">
      <h3 class="title">网站渠道最近7天数据</h3>
      <el-table :data="recentData" border>
        <el-table-column prop="date" label="日期" />
        <el-table-column prop="registerNum" label="注册人数" />
        <el-table-column prop="orderNum" label="订单数" />
        <el-table-column prop="orderPrice" label="订单总额" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { getGlobalEmailCode } from "../api/loginByEmail";
import {
  unblockUser,
  refundUser,
  getAdminHostOverviewData,
  getAdminHostRecentData,
  getBlockList,
  updateBlockStatus,
} from "../api/user";
export default {
  data() {
    return {
      blockUid: "",
      refundUid: "",
      overview: {},
      recentData: [],
      emailCode: "",
      applyUnblockList: [],
    };
  },
  async created() {
    this.getOverviewData();
    this.getRecentData();
    this.getApplyUnblockList();
    getGlobalEmailCode().then((res) => {
      this.emailCode = res.data;
    });
  },
  methods: {
    getOverviewData() {
      getAdminHostOverviewData().then((res) => {
        if (res.code === 0) {
          this.overview = res.data;
        }
      });
    },
    getApplyUnblockList() {
      getBlockList().then((res) => {
        if (res.code === 0) {
          this.applyUnblockList = res.data;
        }
      });
    },
    getRecentData() {
      getAdminHostRecentData().then((res) => {
        if (res.code === 0) {
          this.recentData = res.data;
        }
      });
    },
    async handleRow(item, status) {
      await updateBlockStatus({ unionid: item.unionid, status });
      this.getApplyUnblockList();
    },
    async unblock() {
      const res = await unblockUser(this.blockUid);
      if (res.code == 0) {
        return this.$toast.success("操作成功");
      }
    },
    async refund() {
      const res = await refundUser(this.refundUid);
      if (res.code == 0) {
        this.updateUserinfo();
        return this.$toast.success("操作成功");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.section {
  margin: 15px auto;
  max-width: 900px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  h3 {
    padding-left: 10px;
    border-left: 6px solid $theme-color;
    margin-bottom: 15px;
  }
  .email-code {
    color: $theme-color;
    border-bottom: 1px dashed $theme-color;
  }
  .operation-wrap {
    display: flex;
    .el-input {
      flex: auto;
    }
    .van-button {
      margin-left: 20px;
      height: 40px;
      width: 150px;
      flex-shrink: 0;
    }
  }
  .overview-wrap {
    display: flex;
    justify-content: space-around;
    .overview-item {
      width: 30%;
      .num {
        font-weight: bold;
        font-size: 22px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .tip {
        font-size: 14px;
        color: #666;
      }
    }
  }
  .el-table {
    margin: 0 auto;
  }
}
</style>
